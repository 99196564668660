import React, { ChangeEvent, useState } from 'react';
import MUITableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { ColParam, TableActions } from '../table.models';
import Button from '@mui/material/Button';
import { FiEdit, FiMoreHorizontal } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import MenuItem from '@mui/material/MenuItem';
import { FaUndo, FaUsers } from 'react-icons/fa';
import { Select } from '@mui/material';
import { DropItemsProps } from 'src/pages/sites/components/UserManagement.component';
import { HiOutlineSwitchHorizontal } from 'react-icons/hi';
import MenuComponent from './MenuComponent';

interface Props {
  columns: ColParam[];
  multiSelect: boolean;
  data: any[];
  actions?: TableActions;
  selectedRows?: any[];
  dense?: boolean;
  rowCount?: number;
  showMenuItem?: boolean;
  singleSelect?: boolean;
  onSelectRow: (event: ChangeEvent<HTMLInputElement>, row: any) => void;
  onRowClick?: (row: any) => void;
  isActionAllowed?: (action: { id: string }, row: { is_enabled: string }) => any;
  isMenuItemAllowed?: (menuItem: { id: string }, row: { is_enabled: string }) => any;
  isSelected?: (id: number) => boolean;
  dropdownItems?: DropItemsProps[];
  isDropdownAllowed?: boolean;
  onDropdownChange?: (row: any) => void;
}

function TableBody(props: Readonly<Props>) {
  const [page] = useState(0);
  const [rowsPerPage] = useState(5);
  const [anchorGroupMenu, setAnchorGroupMenu] = React.useState<any>(null);

  // Avoid a layout jump when reaching the last page with empty data.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.data.length) : 0;

  const getActionIcon = (iconName: string) => {
    switch (iconName) {
      case 'more':
        return <FiMoreHorizontal />;
      case 'edit':
        return <FiEdit />;
      case 'delete':
        return <RiDeleteBinLine />;
      case 'users':
        return <FaUsers />;
      case 'restore':
        return <FaUndo />;
      case 'move':
        return <HiOutlineSwitchHorizontal />;
      default:
        return <></>;
    }
  };

  const handleOpenActionMenu = (key: any, event: any) => {
    setAnchorGroupMenu({ [key]: event.currentTarget });
  };

  const handleCloseActionMenu = () => {
    setAnchorGroupMenu(null);
  };

  const handleRowSelection = (event: ChangeEvent<HTMLInputElement>, row: any) => {
    if (props.singleSelect) {
      // If singleSelect mode, only one row can be selected at a time
      // setSelectedRowId(row.id);
      props.onSelectRow(event, []);
    } else {
      // Use the provided onSelectRow for multi-select
      props.onSelectRow(event, row);
    }
  };

  return (
    <MUITableBody>
      {props.data.map((row, rowIndex) => {
        const isRowSelected = props.isSelected?.(row.id);
        const rowActions = props.actions?.singleActions(row);

        return (
          <TableRow key={row.id} tabIndex={-1} selected={isRowSelected} hover={props.onRowClick != undefined}>
            {(props.multiSelect || props.singleSelect) && (
              <TableCell padding='checkbox'>
                <Checkbox color='primary' checked={isRowSelected} onChange={e => handleRowSelection(e, row)} />
              </TableCell>
            )}
            {props.columns.map(colParam => (
              <TableCell
                style={
                  row?.is_enabled === '0'
                    ? { fontWeight: colParam.fontWeight || '400', opacity: 0.2 }
                    : { fontWeight: colParam.fontWeight || '400' }
                }
                onClick={() => {
                  props.onRowClick && props.onRowClick(row);
                }}
                key={colParam.key}
              >
                {row[colParam.key]}
              </TableCell>
            ))}
            {/* one with the issue  */}
            {props.actions && (
              <TableCell key={'actions'} align={'right'}>
                {rowActions?.map((act: any, actionIndex: any) => {
                  const isEditOrDelete = act.id === 'edit' || act.id === 'delete';
                  const isMoveOrRestore = act.id === 'move' || act.id === 'restore';
                  const hasValidDropdownItems = act.dropdownItems?.some((menuItem: any) => props.isMenuItemAllowed?.(menuItem, row));

                  if (isEditOrDelete || isMoveOrRestore || (act.dropdown && hasValidDropdownItems)) {
                    return act.dropdown ? (
                      <>
                        {!props.showMenuItem ? (
                          <Button
                            key={act.id}
                            color='secondary'
                            className='action-button'
                            variant='contained'
                            size='small'
                            disableElevation
                            aria-haspopup='true'
                            onClick={e => handleOpenActionMenu(actionIndex + '' + rowIndex, e)}
                          >
                            {getActionIcon(act.icon)}
                          </Button>
                        ) : null}

                        <MenuComponent
                          anchorGroupMenu={anchorGroupMenu}
                          actionIndex={actionIndex}
                          rowIndex={rowIndex}
                          handleCloseActionMenu={handleCloseActionMenu}
                          act={act}
                          row={row}
                          isMenuItemAllowed={(menuItem, row) => props.isMenuItemAllowed?.(menuItem, row) ?? false}
                          setAnchorGroupMenu={setAnchorGroupMenu}
                        />
                      </>
                    ) : (
                      props.isActionAllowed?.(act, row) && (
                        <Button
                          key={act.id}
                          color='secondary'
                          className='action-button'
                          variant='contained'
                          size='small'
                          disableElevation
                          onClick={() => act.onClick([row])}
                        >
                          {getActionIcon(act.icon)}
                        </Button>
                      )
                    );
                  }

                  return null;
                })}
              </TableCell>
            )}

            {props.isDropdownAllowed && (
              <TableCell>
                {isRowSelected && (
                  <Select
                    size='small'
                    fullWidth
                    value={row.system_role}
                    onChange={event => {
                      const updatedData = props.data.map(rowData =>
                        rowData.id === row.id ? { ...rowData, system_role: event.target.value } : rowData,
                      );
                      props.onDropdownChange && props.onDropdownChange(updatedData);
                    }}
                  >
                    {props.dropdownItems?.map(item => (
                      <MenuItem key={item.key} value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </TableCell>
            )}
          </TableRow>
        );
      })}
      {emptyRows > 0 && (
        <TableRow
          style={{
            height: (props.dense ? 33 : 53) * emptyRows,
          }}
        >
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </MUITableBody>
  );
}

export default TableBody;
