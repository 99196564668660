import React from 'react';
import { Menu, MenuItem } from '@mui/material';

interface DropdownItem {
  id: string;
  label: string;
  onClick: (rows: any[]) => void;
}

interface MenuComponentProps {
  anchorGroupMenu: { [key: string]: HTMLElement | null } | null;
  actionIndex: number;
  rowIndex: number;
  handleCloseActionMenu: () => void;
  act: {
    dropdownItems?: DropdownItem[];
  };
  row: any;
  isMenuItemAllowed: (menuItem: DropdownItem, row: any) => boolean;
  setAnchorGroupMenu: React.Dispatch<React.SetStateAction<{ [key: string]: HTMLElement | null } | null>>;
}

const MenuComponent: React.FC<MenuComponentProps> = ({
  anchorGroupMenu,
  actionIndex,
  rowIndex,
  handleCloseActionMenu,
  act,
  row,
  isMenuItemAllowed,
  setAnchorGroupMenu,
}) => {
  const renderMenuItems = () => {
    return act.dropdownItems
      ?.map(menuItem => {
        if (isMenuItemAllowed(menuItem, row)) {
          return (
            <MenuItem key={menuItem.id} onClick={() => handleMenuItemClick(menuItem, row)}>
              {menuItem.label}
            </MenuItem>
          );
        }
        return null;
      })
      .filter(item => item !== null);
  };

  const handleMenuItemClick = (menuItem: DropdownItem, row: any) => {
    menuItem.onClick([row]);
    setAnchorGroupMenu(null);
  };

  return (
    <Menu
      className='action-menu'
      anchorEl={anchorGroupMenu?.[`${actionIndex}${rowIndex}`]}
      open={Boolean(anchorGroupMenu?.[`${actionIndex}${rowIndex}`])}
      onClose={handleCloseActionMenu}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {renderMenuItems()}
    </Menu>
  );
};

export default MenuComponent;
