import './sideMenu.style.scss';
import { useTranslation } from 'react-i18next';
import { can, getUserRole } from 'src/core/utils/global.utils';
import { useContext } from 'react';
import AppContext from 'src/core/context/global/AppContext';
import { Structure } from 'src/core/models/session.models';
import { Typography } from '@mui/material';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

interface Props {
  openPage: any;
  signOut: any;
  getItemClassName: any;
}

function GroupMenu(props: Readonly<Props>) {
  const { openPage, signOut, getItemClassName } = props;
  const { t } = useTranslation();
  const { user, abilities, setStructure, structure } = useContext(AppContext);

  const backToGroups = () => {
    setStructure(new Structure(JSON.parse('{}')));
    openPage('/groups');
  };
  return (
    <div className='side-menu'>
      <ul className='items'>
        {can(getUserRole(user, structure), ['backOffice_access'], abilities) && (
          <button className={getItemClassName('/groups')} onClick={backToGroups} style={{ width: 214, marginLeft: 10 }}>
            <ArrowBackOutlinedIcon className='item-icon' />
            <Typography noWrap>{t('menu.accountAdministration')}</Typography>
          </button>
        )}
        <button className={getItemClassName('/accounts')} onClick={() => openPage('/accounts')}>
          <HomeOutlinedIcon className='item-icon' />
          <Typography noWrap>{t('menu.accounts')}</Typography>
        </button>
        {/* 
         <button className={getItemClassName('/notifications')} onClick={() => openPage('/notifications')}>
          <BiBell className='item-icon' />
          {t('group.globalNotifications')}
        </button>
        <button className={getItemClassName('/reports')} onClick={() => openPage('/reports')}>
          <HiOutlineClipboardList className='item-icon' />
          {t('group.report')}
        </button>
        <button className={getItemClassName('/fm')} onClick={() => openPage('/fm')}>
          <FiSettings className='item-icon' />
          {t('group.fieldManagement')}
        </button> */}
        <button className={getItemClassName('/admin')} onClick={() => openPage('/admin')}>
          <MenuOutlinedIcon className='item-icon' />
          <Typography noWrap>{t('Administration')}</Typography>
        </button>
        <div className='bottom-items'>
          <button key={'signOut'} className='item' onClick={signOut}>
            <LogoutOutlinedIcon className='item-icon' />
            <Typography noWrap>{t('menu.signOut')}</Typography>
          </button>
        </div>
      </ul>
    </div>
  );
}

export default GroupMenu;
