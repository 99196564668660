import { useState, ChangeEvent, MouseEvent, useEffect } from 'react';
import MUITable from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import TableBody from './components/TableBody.component';
import TablePagination from './components/TablePagination.component';
import TableHead from './components/TableHead.component';
import { ColParam, TableActions } from './table.models';
import './table.style.scss';
import { TableLoading } from '../loading/Loading.component';
import { DropItemsProps } from 'src/pages/sites/components/UserManagement.component';
import { Box, Typography } from '@mui/material';
import { colors } from 'src/assets/styles/theme.style';

interface Props {
  columns: ColParam[];
  data: any[];
  rowsSelected?: any[];
  actions?: TableActions;
  pagination?: {
    pageCount: number;
    rowsPerPage: number;
    pageIndex: number;
  };
  multiSelect?: boolean;
  dense?: boolean;
  isLoading?: boolean;
  showMenuItem?: boolean;
  emptyRowsMessage?: string;
  dropdownItems?: DropItemsProps[];
  isDropdownAllowed?: boolean;
  singleSelect?: boolean;
  handlRow?: (event: ChangeEvent<HTMLInputElement>, row: any[]) => void;
  handlAllRows?: (event: ChangeEvent<HTMLInputElement>) => void;
  onRowClick?: (row: any) => void;
  onChangePage?: (limit: number) => void;
  onChangelimit?: (limit: number) => void;
  onRequestSort?: (property: string, order: string) => void;
  isActionAllowed?: (action: { id: string }, row: any) => any;
  isMenuItemAllowed?: (menuItem: { id: string }, row: { is_enabled: string }) => any;
  isSelected?: (id: number) => boolean;
  onDropdownChange?: (row: any) => void;
  defaultSelectedValue?: number;
}

function Table(props: Readonly<Props>) {
  const { handlRow, handlAllRows, rowsSelected } = props;
  const [orderType, setOrderType] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('mrn');
  const [selectedRows, setSelectedRows] = useState<any[]>(rowsSelected || []);
  const [selectedPage, setSelectedPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [pageCount, setPageCount] = useState<number>(3);

  const handleRequestSort = (_event: MouseEvent<unknown>, property: string, order: string) => {
    setOrderType(order !== 'desc' ? 'desc' : 'asc');
    setOrderBy(property);
    props.onRequestSort && props.onRequestSort(property, order);
  };

  const handleSelectAllRows = (event: ChangeEvent<HTMLInputElement>) => {
    handlAllRows && handlAllRows(event);
  };

  const handleSelectRow = (event: ChangeEvent<HTMLInputElement>, row: any) => {
    handlRow && handlRow(event, row);
  };

  useEffect(() => {
    if (props.pagination) {
      setSelectedPage(props.pagination.pageIndex + 1);
      setRowsPerPage(props.pagination.rowsPerPage);
      setPageCount(props.pagination.pageCount);
    }
  }, [props.pagination]);

  useEffect(() => {
    const newSelectedRows = selectedRows.filter(r => props.data.find(e => e.id == r.id));
    setSelectedRows(newSelectedRows);
  }, [props.data]);

  const root = document.documentElement;
  root.style.setProperty('--th-color', colors.tableHeaderText);
  root.style.setProperty('--td-color', colors.tabTextColor);
  root.style.setProperty('--th-background-color', colors.tableHeaderBg);

  return (
    <Paper className='generic-table'>
      <TableContainer className='table-container'>
        <MUITable stickyHeader aria-labelledby='Dynamic table' size={props.dense ? 'small' : 'medium'}>
          <TableHead
            columns={props.columns}
            rowCount={props.data.length}
            selectedRows={rowsSelected}
            order={orderType}
            orderBy={orderBy}
            multiSelect={!!props.multiSelect}
            actions={props.actions}
            onSelectAllRows={handleSelectAllRows}
            onRequestSort={handleRequestSort}
            showMenuItem={props.showMenuItem}
            isDropdownAllowed={props.isDropdownAllowed}
          />

          {!props.isLoading ? (
            <TableBody
              columns={props.columns}
              data={props.data}
              rowCount={props.data.length}
              selectedRows={rowsSelected}
              actions={props.actions}
              multiSelect={!!props.multiSelect}
              onSelectRow={handleSelectRow}
              onRowClick={props.onRowClick}
              isActionAllowed={props.isActionAllowed}
              isMenuItemAllowed={props.isMenuItemAllowed}
              showMenuItem={props.showMenuItem}
              isSelected={props.isSelected}
              dropdownItems={props.dropdownItems}
              isDropdownAllowed={props.isDropdownAllowed}
              onDropdownChange={props.onDropdownChange}
              singleSelect={props.singleSelect}
            />
          ) : (
            <TableLoading />
          )}
        </MUITable>
        <>
          {!props.data.length && (
            <Box>
              <Typography style={{ margin: 16, fontWeight: '400' }} paddingRight={2}>
                {props.emptyRowsMessage}
              </Typography>
            </Box>
          )}
        </>
      </TableContainer>
      {props.pagination && (
        <TablePagination
          selectedPage={selectedPage}
          pageCount={pageCount}
          rowsPerPage={rowsPerPage}
          onChangePage={(page: number) => {
            props.onChangePage && props.onChangePage(page);
          }}
          onChangelimit={(limit: number) => {
            props.onChangelimit && props.onChangelimit(limit);
          }}
          defaultSelectedValue={props.defaultSelectedValue}
        />
      )}
    </Paper>
  );
}

export default Table;
