import { instance } from 'src/config';
import { AppInstance } from 'src/core/utils/instance.utils';

// White logos
import defaultLogo from 'src/assets/images/insight-healthcare-white.png';
import crLogo from 'src/assets/images/Insight-Research-white.png';
import natroxLogo from 'src/assets/images/natrox-white.png';
import euUkLogo from 'src/assets/images/Insight-Europe-white.png';

// Simple logos
import defaultLogoSimple from 'src/assets/images/insight-helathcare.png';
import crLogoSimple from 'src/assets/images/Insight-Research.png';
import natroxLogoSimple from 'src/assets/images/natrox.png';
import natroxVALogoSimple from 'src/assets/images/natrox-va.png';
import euUkLogoSimple from 'src/assets/images/Insight-Europe.png';

export const UseInstanceLogo = (isWhiteVersion?: boolean): string => {
  switch (instance) {
    case AppInstance.CR:
      return !isWhiteVersion ? crLogo : crLogoSimple;
    case AppInstance.NATROX:
      return !isWhiteVersion ? natroxLogo : natroxLogoSimple;
    case AppInstance.NATROX_VA:
      return !isWhiteVersion ? natroxLogo : natroxVALogoSimple;
    case AppInstance.EU:
      return !isWhiteVersion ? euUkLogo : euUkLogoSimple;
    case AppInstance.UK:
      return !isWhiteVersion ? euUkLogo : euUkLogoSimple;
    case AppInstance.SG:
      return !isWhiteVersion ? defaultLogo : defaultLogoSimple;
    case AppInstance.SINGHEALTH:
      return !isWhiteVersion ? defaultLogo : defaultLogoSimple;
    default:
      return !isWhiteVersion ? defaultLogo : defaultLogoSimple;
  }
};
