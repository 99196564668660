import { useState, createContext, useContext } from 'react';
import AppContext from 'src/core/context/global/AppContext';

//create a context
export const ChartFilterContext = createContext({});

export const ChartFilterProvider = ({ children }: any) => {
  const { user, structure } = useContext(AppContext);
  const emptyData = {
    woundPresence: { presence: [] },
    woundType: { type: [] },
    woundLocation: { primary_location_name: [] },
    patientGender: { gender: [] },
    patientAgeRange: { patientAgeRange: [] },
    woundHealingStatus: { status: [] },
    account: { account: structure.id },
    site: { site_id: [] },
    patientStatus: { patient_status: [] },
    woundStatus: { is_active: [] },
  };
  const [initialChartFilter, setInitialChartFilter] = useState(emptyData);
  const [chartFilter, setChartFilter] = useState(emptyData);

  const values = {
    chartFilter,
    setChartFilter,
    initialChartFilter,
    setInitialChartFilter,
  };

  return <ChartFilterContext.Provider value={values}>{children}</ChartFilterContext.Provider>;
};
