import React, { CSSProperties, useContext } from 'react';
import { colors } from 'src/assets/styles/theme.style';
import { ReactComponent as BackIcon } from 'src/assets/images/SideContainer/icons/backIcon.svg';
import AppContext from 'src/core/context/global/AppContext';
import { isContextSwitchWoundMode } from 'src/core/utils/global.utils';
import { UseInstanceLogo } from 'src/components/logo/useInstanceLogo';
import { isNatroxInstance } from 'src/core/utils/instance.utils';

interface Props {
  className?: string;
  children?: any;
  page?: string;
  onBack?: () => void;
}

export const SideContainer = (props: Props) => {
  const { appMode } = useContext(AppContext);
  const isSpecialPage = (page?: string) => page === 'wounds' || page === 'gallery';

  const handleLogoDisplay = (page?: string) => {
    if (isSpecialPage(page)) {
      return (
        <div style={style.container}>
          {page === 'wounds' && !isContextSwitchWoundMode(appMode) && <BackIcon onClick={props.onBack} style={style.backIcon} />}
          <div style={style.logoContainer}>
            <img src={UseInstanceLogo(true)} alt='logo' style={{ ...style.logo }} />
          </div>
        </div>
      );
    }

    return (
      <div style={style.logoContainer}>
        <img src={UseInstanceLogo(!isNatroxInstance())} alt='logo' style={{ ...style.logo, width: 180, margin: 0 }} />
      </div>
    );
  };

  return (
    <div
      style={{
        ...style.component,
        background: isSpecialPage(props.page) ? colors.woundSideMenuBackground : colors.sideMenuBackground,
      }}
      className={props.className}
    >
      {handleLogoDisplay(props.page)}
      <div style={style.sideContainerElements}>{props.children}</div>
    </div>
  );
};
const style: { [style: string]: CSSProperties } = {
  component: {
    width: '236px',
    height: '100%',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    position: 'absolute',
  },
  logoContainer: {
    margin: '12px 27px 12px 8px',
    cursor: 'pointer',
    textAlign: 'center',
  },
  logo: {
    width: '140px',
  },
  sideContainerElements: {
    width: '100%',
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    maxHeight: '90vh',
  },
  container: {
    marginTop: '12px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginLeft: '24px',
    maxHeight: '10vh',
  },
  backIcon: {
    width: 40,
    height: 40,
    backgroundColor: colors.sideContainer_backIcon,
    borderRadius: 5,
    padding: 8,
    margin: '8px 0px',
    cursor: 'pointer',
  },
};
