import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import Routes from 'src/routes';
import Parse from 'parse';
import { ThemeProvider } from '@mui/material/styles';
import { themes } from 'src/assets/styles/mui.theme';
import AlertDialog from 'src/components/dialogs/AlertDialog.component';
import AppContext from 'src/core/context/global/AppContext';
import { app_version, instance, ParseAppID, ParseServerURL } from './config';
import 'src/assets/locales/i18n';
import 'src/assets/styles/global.style.scss';
import { toggleZendeskWidget } from './core/utils/global.utils';
import { AppInstance } from './core/utils/instance.utils';
import LogoutTimer from './components/containers/LogoutTimer.container';
import VerifySession from './components/containers/VerifySession.container';
import SnackbarComponent from './components/snackbar/Snackbar.component';
import { LicenseInfo } from '@mui/x-license';

// Initialize Parse server
// TODO: Get these params from API config
Parse.initialize(ParseAppID);
Parse.serverURL = ParseServerURL;
// License key for MUI DataGrid
// TODO : change the location of the license key to the config file
LicenseInfo.setLicenseKey('cb7d0d474c55a1bb5a356ee509e95e61Tz02MjYwNixFPTE3MTExNDM1MzM2NjUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
function App() {
  const { alertDialogProps, snackBarProps, setAlertDialogProps, setSnackBarProps } = useContext(AppContext);
  const [theme, setTheme] = useState(themes[instance]);

  const handleCloseAlertDialog = () => {
    setAlertDialogProps({});
  };

  const handleConfirmAlertDialog = async () => {
    (await alertDialogProps.onConfirm()) && setAlertDialogProps();
  };

  const closeSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    setSnackBarProps();
  };

  useEffect(() => {
    setTheme(themes[instance]);
  }, [instance]);

  useLayoutEffect(() => {
    toggleZendeskWidget(false);
  }, []);

  useEffect(() => {
    let title = '';
    let faviconPath = '';

    switch (instance) {
      case AppInstance.CR:
        title = `eKare inSight CR ${app_version}`;
        faviconPath = './favicon/favicon-CR1.ico';
        break;
      case AppInstance.NATROX:
        title = `Natrox Wound Care ${app_version}`;
        faviconPath = './favicon/favicon-NTX-SG.ico';
        break;
      case AppInstance.NATROX_VA:
        title = `Natrox VA ${app_version}`;
        faviconPath = './favicon/favicon-NTX-SG.ico';
        break;
      case AppInstance.EU:
        title = `eKare inSight EU ${app_version}`;
        faviconPath = './favicon/favicon.ico';
        break;
      case AppInstance.UK:
        title = `eKare inSight EU (UK) ${app_version}`;
        faviconPath = './favicon/favicon.ico';
        break;
      default:
        title = `eKare inSight v${app_version}`;
        faviconPath = './favicon/favicon.ico';
        break;
    }

    document.title = title;

    const favicon = document.getElementById('favicon') as HTMLLinkElement;
    if (favicon) {
      favicon.href = faviconPath;
    } else {
      const newFavicon = document.createElement('link');
      newFavicon.id = 'favicon';
      newFavicon.rel = 'icon';
      newFavicon.href = faviconPath;
      document.head.appendChild(newFavicon);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {snackBarProps && snackBarProps.open && (
        <SnackbarComponent
          open={snackBarProps.open}
          severity={snackBarProps.severity}
          message={snackBarProps.message}
          onClose={closeSnackbar}
        />
      )}
      {alertDialogProps?.open && (
        <AlertDialog
          loadingAnimation={alertDialogProps.loadingAnimation}
          open={alertDialogProps.open}
          title={alertDialogProps.title}
          content={alertDialogProps.content}
          onClose={handleCloseAlertDialog}
          onConfirm={handleConfirmAlertDialog}
          closeLabel={alertDialogProps.closeLabel}
          confirmLabel={alertDialogProps.confirmLabel}
        />
      )}
      <VerifySession>
        {/* <LogoutTimer> */}
        <Routes />
        {/* </LogoutTimer> */}
      </VerifySession>
    </ThemeProvider>
  );
}

export default App;
