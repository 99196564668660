import { lazy, Suspense, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { PageLoading } from 'src/components/loading/Loading.component';
import { router_basename } from './config';
import AppContext from './core/context/global/AppContext';
import { PatientContextProvider } from './core/context/patient/PatientContext';
import { WoundContextProvider } from './core/context/wound/WoundContext';
import { GroupContextProvider } from './core/context/group/GroupContext';
import GroupUsers from './pages/groups/groupUsers/GroupUsers.page';
import { appToAppPages, isContextSwitchWoundMode } from './core/utils/global.utils';
import { ChartFilterProvider } from 'src/core/context/dashboard/ChartFilter.context';
import DashboardProvider from 'src/core/context/dashboard/Dashboard.context';

// Code splitting (https://reactjs.org/docs/code-splitting.html#route-based-code-splitting)
const LoginPage = lazy(() => import('src/pages/login/Login.page'));
const PatientsPage = lazy(() => import('src/pages/patients/Patients.page'));
const WoundsPage = lazy(() => import('src/pages/wounds/Wounds.page'));
const WoundsMockUpPage = lazy(() => import('src/pages/mockup/WoundsMockUp.page'));
const UsersPage = lazy(() => import('src/pages/users/Users.page'));
const SettingsPage = lazy(() => import('src/pages/settings/Settings.page'));
const ReportsPage = lazy(() => import('src/pages/reports/Reports.page'));
const LogsReport = lazy(() => import('src/pages/logsReport/LogsReport.page'));
const DashboardPage = lazy(() => import('src/pages/dashboard/Dashboard.page'));
const AboutPage = lazy(() => import('src/pages/about/About.page'));
const SitesPage = lazy(() => import('src/pages/sites/Sites.page'));
const AccountsPage = lazy(() => import('src/pages/accounts/Accounts.page'));
const GroupsPage = lazy(() => import('src/pages/groups/Groups.page'));
const GalleryPage = lazy(() => import('src/pages/gallery/Gallery.page'));
const AdminNotificationsPage = lazy(() => import('src/pages/notifications/AdminNotifications.page'));
const ActiveUsers = lazy(() => import('src/pages/activeUsers/ActiveUsers.page'));
const IntegrationPage = lazy(() => import('src/pages/integration/Integration.page'));
const UnauthorizedPage = lazy(() => import('src/pages/unauthorized/Unauthorized.page'));
const HealBot = lazy(() => import('src/pages/healBot/HealBot.page'));
const AdminPage = lazy(() => import('src/pages/admin/Admin.page'));

function AppRoutes() {
  return (
    <Router basename={router_basename}>
      <Suspense fallback={<PageLoading />}>
        <GroupContextProvider>
          <Routes>
            <Route
              path='/patients'
              element={
                <PrivateRoute page='patients'>
                  <PatientContextProvider>
                    <PatientsPage />
                  </PatientContextProvider>
                </PrivateRoute>
              }
            />
            <Route
              path='/groups'
              element={
                <PrivateRoute page='groups'>
                  <GroupsPage />
                </PrivateRoute>
              }
            />
            <Route
              path='/adminNotifications'
              element={
                <PrivateRoute page='adminNotifications'>
                  <AdminNotificationsPage />
                </PrivateRoute>
              }
            />
            <Route
              path='/accounts'
              element={
                <PrivateRoute page='accounts'>
                  <PatientContextProvider>
                    <AccountsPage />
                  </PatientContextProvider>
                </PrivateRoute>
              }
            />
            <Route
              path='/dashboard'
              element={
                <PrivateRoute page='dashboard'>
                  <ChartFilterProvider>
                    <DashboardProvider>
                      <DashboardPage />
                    </DashboardProvider>
                  </ChartFilterProvider>
                </PrivateRoute>
              }
            />
            <Route
              path='/users'
              element={
                <PrivateRoute page='users'>
                  <UsersPage />
                </PrivateRoute>
              }
            />
            <Route
              path='/groupUsers'
              element={
                <PrivateRoute page='groupUsers'>
                  <GroupUsers />
                </PrivateRoute>
              }
            />
            <Route
              path='/sites'
              element={
                <PrivateRoute page='sites'>
                  <SitesPage />
                </PrivateRoute>
              }
            />
            <Route
              path='/healBot'
              element={
                <PrivateRoute page='healBot'>
                  <HealBot />
                </PrivateRoute>
              }
            />
            <Route
              path='/settings'
              element={
                <PrivateRoute page='settings'>
                  <SettingsPage />
                </PrivateRoute>
              }
            />
            <Route
              path='/reports'
              element={
                <PrivateRoute page='reports'>
                  <ReportsPage />
                </PrivateRoute>
              }
            />
            <Route
              path='/logsReport'
              element={
                <PrivateRoute page='logsReport'>
                  <LogsReport />
                </PrivateRoute>
              }
            />
            <Route
              path='/activeUsers'
              element={
                <PrivateRoute page='activeUsers'>
                  <ActiveUsers />
                </PrivateRoute>
              }
            />
            <Route
              path='/wounds'
              element={
                <PrivateRoute page='wounds'>
                  <WoundContextProvider>
                    <WoundsPage />
                  </WoundContextProvider>
                </PrivateRoute>
              }
            />
            <Route
              path='/gallery'
              element={
                <PrivateRoute page='gallery'>
                  <WoundContextProvider>
                    <GalleryPage />
                  </WoundContextProvider>
                </PrivateRoute>
              }
            />
            <Route
              path='/admin'
              element={
                <PrivateRoute page='admin'>
                  <PatientContextProvider>
                    <AdminPage />
                  </PatientContextProvider>
                </PrivateRoute>
              }
            />
            <Route path='/login' element={<LoginPage />} />
            <Route path='/*' element={<Navigate to={'/login'} />} />
            <Route
              path='/about'
              element={
                <PrivateRoute page='about'>
                  <AboutPage />
                </PrivateRoute>
              }
            />
            <Route path='/integration/:integrationClient' element={<IntegrationPage />} />
            <Route path='/WoundsMockUpPage' element={<WoundsMockUpPage />} />
            <Route path='/unauthorized' element={<UnauthorizedPage />} />
          </Routes>
        </GroupContextProvider>
      </Suspense>
    </Router>
  );
}

function PrivateRoute({ children, page }: any) {
  const { user, appMode } = useContext(AppContext);
  if (isContextSwitchWoundMode(appMode) && !appToAppPages.includes(page)) {
    return <Navigate to={'/unauthorized'} />;
  }

  if (user.isAuthenticated()) {
    return children;
  }

  return <Navigate to={'/login'} />;
}

export default AppRoutes;
