import axios, { AxiosHeaders, AxiosRequestHeaders, Method } from 'axios';
import { api_pass, api_url, router_basename } from 'src/config';
import { Structure, User } from '../models/session.models';
import { getInstanceID } from '../utils/instance.utils';
import { Patient } from '../models/patient.models';

const apiTimeout = 5 * 60000;
export default class ApiService {
  protected static apiGet(path: string, params = {}): Promise<any> {
    return this.apiRun('get', path, {}, params);
  }

  protected static apiPost(path: string, body: any = {}, params: any = {}, action = ''): Promise<any> {
    return this.apiRun('post', path, body, params, undefined, action);
  }

  protected static apiDelete(path: string, body: any = {}, params: any = {}, action = ''): Promise<any> {
    return this.apiRun('delete', path, body, params, undefined, action);
  }

  protected static apiPut(path: string, body: any = {}, params: any = {}, action = ''): Promise<any> {
    return this.apiRun('put', path, body, params, undefined, action);
  }

  protected static apiPutVoid(path: string, body: any = {}, params: any = {}, action = ''): void {
    this.apiRunVoid('put', path, body, params, undefined, action);
  }

  protected static postReport(path: string, body: any = {}, params: any = {}): Promise<any> {
    return this.apiRun('post', path, body, params, api_url.replace('/web', '/report'));
  }

  protected static apiPutFreeze(path: string, clinic: string | number, body: any = {}, params: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      axios({
        url: path,
        method: 'put',
        baseURL: api_url,
        params,
        data: body,
        headers: { ...this.getHeaders(), clinic },
        timeout: apiTimeout,
      })
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          const errorData = error.response && error.code ? error.response.data : undefined;
          reject(errorData);
        });
    });
  }

  protected static postCsv(path: string, body: any = {}, params: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      axios({
        url: path,
        method: 'post',
        baseURL: api_url,
        params,
        data: body,
        headers: this.getHeaders(),
        timeout: apiTimeout,
      })
        .then(res => {
          const fileName = res.headers['content-disposition'].split(';')[1].split('filename=')[1].trim();
          const csvData = res.data;
          resolve({ csvData, fileName });
        })
        .catch(error => {
          const errorData = error.response && error.code ? error.response.data : undefined;
          reject(errorData);
        });
    });
  }

  protected static getImages(path: string, body: any = {}, params: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      axios({
        url: path,
        method: 'post',
        baseURL: api_url,
        params,
        data: body,
        headers: this.getHeaders(),
        timeout: apiTimeout,
      })
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          const errorData = error.response && error.code ? error.response.data : undefined;
          reject(errorData);
        });
    });
  }

  protected static getCsv(path: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios({
        url: path,
        method: 'get',
        baseURL: api_url,
        headers: this.getHeaders(),
        timeout: apiTimeout,
      })
        .then(res => {
          const csvData = res.data;
          const fileName = 'users_' + getInstanceID() + '_' + Date.now();
          resolve({ csvData, fileName });
        })
        .catch(error => {
          const errorData = error.response && error.code ? error.response.data : undefined;
          reject(errorData);
        });
    });
  }

  protected static postReportPDF(path: string, body: any = {}, params: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      axios({
        url: path,
        method: 'post',
        baseURL: api_url,
        params,
        data: body,
        headers: { ...this.getHeaders(), Accept: 'application/pdf' },
        timeout: apiTimeout,
        responseType: 'blob',
      })
        .then((res: any) => {
          const blob = new Blob([res.data], { type: 'application/pdf' });
          const fileName = res.headers['content-disposition'].split(';')[1].split('filename=')[1].trim();
          resolve({ blob, fileName });
        })
        .catch((error: any) => {
          const errorData = error.response && error.code ? error.response.data : undefined;
          reject(errorData);
        });
    });
  }

  protected static postReportZip(path: string, body: any = {}, params: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      axios({
        url: path,
        method: 'post',
        baseURL: api_url,
        params,
        data: body,
        headers: { ...this.getHeaders(), Accept: 'application/zip', 'Content-Type': 'application/zip' },
        timeout: apiTimeout,
        responseType: 'blob',
      })
        .then((res: any) => {
          const blob = new Blob([res.data], { type: 'application/zip' });
          const fileName = res.headers['content-disposition'].split(';')[1].split('filename=')[1].trim();
          resolve({ blob, fileName });
        })
        .catch((error: any) => {
          const errorData = error.response && error.code ? error.response.data : undefined;
          reject(errorData);
        });
    });
  }

  private static apiRun(method: Method, url: string, body: any, params: any, baseURL?: string, action = ''): Promise<any> {
    return new Promise((resolve, reject) => {
      const cacheBuster = new Date().getTime();
      const updatedParams = { ...params, timestamp: cacheBuster };

      axios({
        url,
        method,
        baseURL: baseURL ?? api_url,
        params: updatedParams,
        data: body,
        headers: this.getHeaders(action),
        timeout: apiTimeout,
      })
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          const excludedPaths = ['/login', '/integration'];
          const isExcludedPath = excludedPaths.some(path => window.location.pathname.includes(path));
          if (!isExcludedPath) {
            if (error.response.status == 401) {
              sessionStorage.clear();
              const loginPath = router_basename.length === 1 ? '/login' : `${router_basename}/login`;
              window.location.href = `${loginPath}?session=expired`;
            }
          }

          const errorData = error.response && error.code ? error.response.data : undefined;
          reject(errorData);
        });
    });
  }

  private static apiRunVoid(method: Method, url: string, body: any, params: any, baseURL?: string, action = ''): void {
    const cacheBuster = new Date().getTime();
    const updatedParams = { ...params, timestamp: cacheBuster };

    axios({
      url,
      method,
      baseURL: baseURL ?? api_url,
      params: updatedParams,
      data: body,
      headers: this.getHeaders(action),
      timeout: apiTimeout,
    })
      .then(() => {
        console.log('Session cleaned');
      })
      .catch(() => {
        console.log('nothing to clean');
      });
  }

  private static getHeaders(action = ''): AxiosRequestHeaders {
    const user = new User(JSON.parse(sessionStorage.getItem('_user') ?? '{}'));
    const structure = new Structure(JSON.parse(sessionStorage.getItem('_structure') ?? '{}'));
    const patient = new Patient(JSON.parse(sessionStorage.getItem('_patient') ?? '{}'));
    const actions = [
      'addWound',
      'editWound',
      'deleteWound',
      'changeWoundStatus',
      'sendWoundUpdate',
      'addAssessment',
      'addTreatment',
      'saveMeasurement',
    ];

    return new AxiosHeaders({
      source: 'ekare-portal',
      language: localStorage.getItem('lang') ?? 'en',
      api_pass: api_pass,
      api_key: user.api_key ?? '',
      'api-pass': api_pass,
      'api-key': user.api_key ?? '',
      Authorization: user.access_token ?? '',
      clinic: structure.id || '',
      user: user.username ?? '',
      user_id: user.id || '',
      'user-id': user.id || '',
      mrn: actions.includes(action) ? patient.mrn : '',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    });
  }
}
