import { isSinghealthInstance } from '../utils/instance.utils';

export class Patient {
  id_patient: number;
  site_name: string;
  mrn: string;
  personal_number: string;
  main_address: PatientAddress;
  delivery_address: PatientAddress;
  first_name: string;
  last_name: string;
  initials: string;
  dob: string;
  gender: string;
  phone: string;
  address: string;
  line2: string;
  zip: string;
  is_enabled: '1' | '0';
  is_deleted: '1' | '0';
  prefix: string | null;
  insurance_number: string;
  insurer_code: string;
  unit: string;
  bed: string;
  room: string;
  Clinic_id_clinic: number;
  access_enabled: boolean;
  active_wounds: number;
  child_updated_at: string;
  consent: string;
  created_at: string;
  last_update: string;
  object_values: any;
  clinic: number | string;
  total: any;
  email?: string;
  patient_reminder?: string[];
  email_confirmed?: string;
  follow: boolean;

  constructor(patient: any) {
    this.clinic = patient.clinic;
    this.id_patient = patient?.id_patient || '0';
    this.site_name = patient.site_name;
    this.mrn = patient.mrn;
    this.personal_number = patient.personal_number;
    this.main_address = patient.main_address;
    this.delivery_address = patient.delivery_address;
    this.first_name = patient.first_name;
    this.last_name = patient.last_name;
    this.initials = patient.initials;
    this.dob = patient.dob;
    this.gender = patient.gender;
    this.phone = patient.phone;
    this.address = patient.address;
    this.line2 = patient.line2;
    this.zip = patient.zip;
    this.is_enabled = patient.is_enabled;
    this.is_deleted = patient.is_deleted;
    this.prefix = patient.prefix;
    this.insurance_number = patient.insurance_number;
    this.insurer_code = patient.insurer_code;
    this.unit = patient.unit;
    this.bed = patient.bed;
    this.room = patient.room;
    this.Clinic_id_clinic = patient.Clinic_id_clinic;
    this.access_enabled = patient.access_enabled;
    this.active_wounds = patient.active_wounds;
    this.child_updated_at = patient.child_updated_at;
    this.consent = patient.consent;
    this.created_at = patient.created_at;
    this.last_update = patient.last_update;
    this.delivery_address = patient.delivery_address;
    this.object_values = patient.object_values;
    this.total = patient.total;
    this.email = patient.email;
    this.patient_reminder = patient.patient_reminder;
    this.email_confirmed = patient.email_confirmed;
    this.follow = patient.follow;
  }
}

export class GetPatientsReq {
  clinic?: string | number;
  search_keyword?: string;
  clinician?: number;
  department?: number;
  location_unit?: string;
  active_patient_only?: 0 | 1;
  active_wounds_only?: 0 | 1;
  appointments?: 0 | 1 | 2 | 3;
  start_date?: string;
  end_date?: string;
  appointment_responsible?: number;
  limit?: number;
  offset?: number;
  is_ascending?: 0 | 1;
  sort_field?: string;
  v2?: boolean;

  constructor(filter: PatientFilter) {
    this.clinic = filter.clinic;
    this.search_keyword = filter.searchKeyword;
    this.clinician = filter.assignedClinician;
    this.department = filter.department;
    this.location_unit = filter.location.trim();
    this.active_patient_only = filter.activePatientsOnly ? 1 : 0;
    this.active_wounds_only = filter.activeWoundsOnly ? 1 : 0;
    this.appointments = filter.appointments;
    this.start_date = filter.appointmentsStartDate;
    this.end_date = filter.appointmentsEndDate;
    this.appointment_responsible = filter.appointmentsClinician ? 1 : 0;
    this.limit = filter.limit;
    this.offset = filter.offset;
    this.is_ascending = filter.isAscending ?? 0;
    this.sort_field = filter.sortByField ?? 'last_update';
    this.v2 = true;
  }
}

export interface PatientAddress {
  zip_code: string;
  street: string;
  city: string;
  house_number: string;
  house_number_addition: string;
}

export class PatientFilter {
  clinic: string | number;
  searchKeyword: string;
  assignedClinician: number;
  department: number;
  location: string;
  activePatientsOnly: boolean;
  activeWoundsOnly: boolean;
  appointments: 0 | 1 | 2 | 3;
  appointmentsStartDate: string;
  appointmentsEndDate: string;
  appointmentsClinician: number;
  limit: number;
  offset: number;
  isAscending: 0 | 1;
  sortByField: string;
  includeDeleted: boolean;

  static readonly APPOINTMENTS_TODAY = 1;
  static readonly APPOINTMENTS_THIS_WEEK = 2;
  static readonly APPOINTMENTS_CUSTOM_DATE = 3;

  constructor(filter: any = {}) {
    this.clinic = filter.clinic;
    this.searchKeyword = filter.searchKeyword || '';
    this.assignedClinician = filter.assignedClinician || 0;
    this.department = filter.department || 0;
    this.location = filter.location || ' ';
    this.activePatientsOnly = filter.activePatientsOnly !== undefined ? filter.activePatientsOnly : !isSinghealthInstance();
    this.activeWoundsOnly = filter.activeWoundsOnly !== undefined ? filter.activeWoundsOnly : false;
    this.appointments = filter.appointments || 0;
    this.appointmentsStartDate = filter.appointmentsStartDate || new Date();
    this.appointmentsEndDate = filter.appointmentsEndDate || new Date();
    this.appointmentsClinician = filter.appointmentsClinician || 0;
    this.limit = filter.limit || 25;
    this.offset = filter.offset || 0;
    this.isAscending = filter.isAscending !== undefined ? filter.isAscending : 0;
    this.sortByField = filter.sortByField !== undefined ? filter.sortByField : 'last_update';
    this.includeDeleted = filter.includeDeleted !== undefined ? filter.includeDeleted : true;
  }
}

export interface PatientLocation {
  id: string;
  unit: string;
}

export interface PatientDepartment {
  id: string;
  code: string;
  name: string;
}

export interface FreezePatient {
  mrn: string;
  is_enabled: string;
  id_patient: number;
}

export interface FreezeMultiplePatient {
  patient_ids: number[];
  is_enabled: boolean;
  clinic_id: number;
}

export interface NewPatient {
  data: Patient[];
  total: number;
  page: number;
  limit: number;
  next: string;
  previous: string;
  info: Info;
  message: string;
}

export interface Info {
  strictPatientAccess: StrictPatientAccessProp;
}

export interface StrictPatientAccessProp {
  enabled: boolean;
  message: string;
}
