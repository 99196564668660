import { instance } from 'src/config';

export enum AppInstance {
  IS = 'eKare',
  CR = 'CR',
  EU = 'WoundWorks',
  UK = 'UK',
  SG = 'SG',
  NATROX = 'NTX-SG',
  SINGHEALTH = 'SH',
  NATROX_VA = 'NTX-VA',
}

export enum AppEnv {
  TEST = 'test',
  DEV = 'dev',
  PREPROD = 'preprod',
  PROD = 'prod',
}

export const isStandardInstance = () => {
  return [AppInstance.IS, AppInstance.EU, AppInstance.UK, AppInstance.SG, AppInstance.SINGHEALTH].includes(instance);
};

export const isInsightInstance = () => {
  return AppInstance.IS === instance;
};

export const isResearchInstance = () => {
  return AppInstance.CR === instance;
};

export const isNatroxInstance = () => {
  return [AppInstance.NATROX, AppInstance.NATROX_VA].includes(instance);
};

export const isSinghealthInstance = () => {
  return AppInstance.SINGHEALTH === instance;
};

export const getInstanceID = () => {
  if (instance === AppInstance.IS) {
    return 'IS';
  } else if (instance === AppInstance.EU) {
    return 'EU';
  } else {
    return instance;
  }
};
