import { Box, MenuItem, Select, Stack, Typography } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  selectedPage: number;
  pageCount: number;
  rowsPerPage: number;
  onChangePage: (page: number) => void;
  onChangelimit: (limit: number) => void;
  defaultSelectedValue?: number;
}

const TablePagination = (props: Props) => {
  const { t } = useTranslation();
  const defaultSelectedValue = props.defaultSelectedValue ?? 15;
  const [selectedValue, setSelectedValue] = useState(defaultSelectedValue);

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    props.onChangePage(page);
  };

  const handleSelectChange = (event: any) => {
    setSelectedValue(event.target.value);
    props.onChangelimit(event.target.value);
    props.onChangePage(1);
  };

  useEffect(() => {
    setSelectedValue(props.rowsPerPage);
  }, [props.rowsPerPage]);

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      margin={2}
      paddingLeft={4}
      paddingRight={16}
      height={40}
      paddingTop={2}
    >
      <Stack spacing={2}>
        <Pagination
          page={props.selectedPage}
          count={props.pageCount}
          shape='rounded'
          color='primary'
          showFirstButton
          showLastButton
          onChange={handlePageChange}
        />
      </Stack>
      <Box display='flex' alignItems='center'>
        <Typography style={{}} paddingRight={2}>
          {t('common.resultsPerPage')}
        </Typography>
        <Select value={selectedValue} onChange={handleSelectChange}>
          <MenuItem value={15}>15</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
      </Box>
    </Box>
  );
};

export default TablePagination;
