import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import SideMenu from 'src/components/menu/sideMenu/SideMenu.component';
import { User } from 'src/core/models/user.models';
import UserService from 'src/core/services/user.service';
import AppContext from 'src/core/context/global/AppContext';
import './groupsUsers.style.scss';
import GroupUsersList from './components/GroupUsersList.component';
import UsersHeader from './components/GroupUsersHeader';
import { FormMode } from 'src/core/utils/global.utils';
import SnackbarComponent from 'src/components/snackbar/Snackbar.component';
import { useLocation } from 'react-router-dom';
import GroupUsersForm from './components/GroupUsersForm.component';

export default function GroupUsers() {
  const { setAlertDialogProps } = useContext(AppContext);
  const { t } = useTranslation();
  const [users, setUsers] = useState<User[]>([]);
  const [data, setData] = useState<User[]>([]);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [showUserForm, setShowUserForm] = useState<boolean>(false);
  const [onSaveLoading, setOnSaveLoading] = useState(false);
  const [userOnForm, setUserOnForm] = useState<User>();
  const [snackbar, setSnackbar] = useState<any>({
    open: false,
    severity: '',
    message: '',
  });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const clinic = searchParams.get('clinic');

  const getUsers = async () => {
    setPageLoading(true);
    try {
      const getUsersResp: User[] = await UserService.getGroupUsers({
        activeOnly: 0,
        clinic: Number(clinic),
        status: -1,
      });
      setUsers(getUsersResp);
      setData(getUsersResp);
    } catch (error) {
      setUsers([]);
      setSnackbar({ open: true, severity: 'error', message: t('common.errorOccurred') });
    } finally {
      setPageLoading(false);
    }
  };

  const handleAddUserClick = () => {
    setUserOnForm(undefined);
    setShowUserForm(true);
  };

  const handleEditUser = ([userRow]: any) => {
    const currentUser = users.find(u => u.id == userRow.id);
    currentUser && setUserOnForm({ ...currentUser, confirm_email: currentUser?.email });
    setShowUserForm(true);
  };

  const closeSnackbar = () => {
    setSnackbar({
      open: false,
      severity: '',
      message: '',
    });
  };

  const handleDeleteUsers = (userRows: any[]) => {
    const dialogTitle = userRows.length > 1 ? t('user.deleteUsers') : t('user.deleteUser');
    const dialogContent = userRows.length > 1 ? t('user.confirmDeleteUsers') : t('user.confirmDeleteUser');
    setAlertDialogProps({
      open: true,
      title: dialogTitle,
      content: dialogContent,
      confirmLabel: t('common.yes'),
      closeLabel: t('common.no'),
      onConfirm: async () => {
        if (userRows.length > 0) {
          const res = await UserService.deleteGroupUsers(userRows[0].id);
          if (res) {
            getUsers();
            return true;
          } else {
            setSnackbar({
              open: true,
              severity: 'error',
              message: res.messages,
            });
          }
        } else {
          setSnackbar({
            open: true,
            severity: 'error',
            message: t('common.errorOccurred'),
          });
        }
      },
    });
  };

  const onInviteUser = async (users: User[]) => {
    if (users.length > 0) {
      const res = await UserService.inviteUser(users[0].id);
      if (res) {
        setSnackbar({
          open: true,
          severity: res.error ? 'error' : 'success',
          message: res.message || res.messages,
        });
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          message: t('common.errorOccurred'),
        });
      }
    }
  };

  const closeUserForm = () => {
    setShowUserForm(false);
  };
  const searchObjects = (usersList: User[], searchTerm: string) => {
    return usersList.filter(obj =>
      Object.values(obj).some(value => typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())),
    );
  };
  const handleSearch = (item: any) => {
    const res = searchObjects(users, item);

    if (item) {
      setData(res);
    } else {
      getUsers();
    }
  };
  useEffect(() => {
    getUsers();
  }, []);

  const createOrUpdateUser = async (formData: any, type: FormMode) => {
    setOnSaveLoading(true);
    let result;
    switch (type) {
      case FormMode.Edit:
        result = await UserService.updateUser(formData);
        break;
      case FormMode.Add:
        result = await UserService.AddUser({ ...formData, clinic_id_clinic: clinic });
        break;
    }

    if (result !== false && !result.error) {
      setShowUserForm(false);
      getUsers();
      setSnackbar({
        open: true,
        severity: 'success',
        message: result.messages,
      });
    } else {
      setUserOnForm({ ...formData });
      setSnackbar({
        open: true,
        severity: 'error',
        message: result.messages,
      });
    }
    setOnSaveLoading(false);
  };
  return (
    <div className='page users-page'>
      <SideMenu />
      <div className='page-container p24'>
        <UsersHeader
          handleAddUserClick={handleAddUserClick}
          handleSearch={e => {
            handleSearch(e);
          }}
        />
        <GroupUsersList
          users={data}
          onEditUser={handleEditUser}
          onDeleteUsers={handleDeleteUsers}
          onInviteUser={onInviteUser}
          isLoading={pageLoading}
        />
      </div>
      <SnackbarComponent open={snackbar.open} severity={snackbar.severity} message={snackbar.message} onClose={closeSnackbar} />

      {showUserForm && (
        <GroupUsersForm
          show={showUserForm}
          formUser={userOnForm}
          onClose={closeUserForm}
          onSave={(formData, type) => createOrUpdateUser(formData, type)}
          onSaveLoading={onSaveLoading}
        />
      )}
    </div>
  );
}
