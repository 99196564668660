/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-empty */
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import AppContext from 'src/core/context/global/AppContext';
import { Alert, Box, Button, Checkbox, Divider, FormControlLabel, Grid, MenuItem, Select, TextField } from '@mui/material';
import { User } from 'src/core/models/user.models';
import { ComponentLoading } from 'src/components/loading/Loading.component';
import { contactEmailPattern, contactPhonePattern, FormMode, namePattern } from 'src/core/utils/global.utils';

interface ComponentProps {
  show: boolean;
  formUser: any;
  onClose: () => void;
  onSave: (formData: any, type: FormMode) => void;
  onSaveLoading: boolean;
}

const getInitialUserProfile = (formUser: any, structure: any) => ({
  ...new User(formUser),
  clinic_id_clinic: formUser?.clinic_id_clinic ? formUser.clinic_id_clinic : structure.id,
  confirm_email: formUser?.confirm_email ? formUser.confirm_email : undefined,
  system_role: formUser?.system_role ? formUser.system_role : 'class_admin',
  clinical_role: formUser?.clinical_role ? formUser.clinical_role : 'Physician',
  email: formUser?.email ? formUser.email : '',
  status: formUser?.status ? formUser.status : '1',
  site_user: false,
  first_name: formUser?.first_name ? formUser.first_name : '',
  last_name: formUser?.last_name ? formUser.last_name : '',
  phone: formUser?.phone ? formUser.phone : '',
  username: formUser?.username ? formUser.username : '',
});

const GroupUsersForm = (props: ComponentProps) => {
  const { show, onClose, onSave, onSaveLoading, formUser } = props;
  const { structure } = useContext(AppContext);
  const { t } = useTranslation();
  const [isNewUser, setIsNewUser] = useState<boolean>(false);
  const [isSave, setIsSave] = useState(false);
  const initialUserProfile = getInitialUserProfile(formUser, structure);
  const [userProfile, setUserProfile] = useState(initialUserProfile);

  const selectedRoles = [
    { value: 'class_admin', label: t('group.class_admin') },
    { value: 'class_user', label: t('group.class_user') },
  ];

  const genericHandleChange = (key: string, newValue: string) => {
    const newData: any = { ...userProfile };
    newData[key] = newValue;
    setUserProfile(newData);
  };

  const compareEmails = (email: string, confirmEmail: string) => {
    return email !== confirmEmail ? t('validate.confirmEmail_patternMsg') : '';
  };

  const handleTextMsg = (key: string) => {
    switch (key) {
      case 'first_name':
        return userProfile.first_name === '' ? t('validate.firstName_required') : t('validate.firstName_patternMsg');

      case 'last_name':
        return userProfile.last_name === '' ? t('common.search') : t('validate.lastName_patternMsg');

      case 'username':
        return userProfile.username === '' ? t('validate.common.username_required') : t('group.username_patternMsg');

      case 'email':
        return userProfile.email === '' ? t('validate.contactEmail_required') : t('validate.validEmailMessage');

      case 'confirm_email':
        return userProfile.confirm_email === undefined || userProfile.confirm_email === ''
          ? t('validate.contactEmail_required')
          : compareEmails(userProfile.email, userProfile.confirm_email);

      case 'phone':
        break;

      default:
        break;
    }
  };

  const handleHelperTextProp = (key: string) => {
    const validateField = (field: string, text: string, pattern: RegExp) => {
      return isSave && (text === undefined || text === '' || !pattern.test(text)) ? handleTextMsg(field) : '';
    };
    switch (key) {
      case 'first_name':
        return validateField('first_name', userProfile.first_name, namePattern);
      case 'last_name':
        return validateField('last_name', userProfile.last_name, namePattern);
      case 'username':
        return validateField('username', userProfile.username, namePattern);
      case 'email':
        return validateField('email', userProfile.email, contactEmailPattern);
      case 'confirm_email':
        return isSave ? handleTextMsg('confirm_email') : '';
      case 'phone':
        return userProfile.phone !== '' && !contactPhonePattern.test(userProfile.phone) ? t('validate.contactPhone_patternMsg') : '';
      default:
        break;
    }
  };

  const handleErrorProp = (key: string) => {
    /* placeholder */
    switch (key) {
      case 'first_name':
        return userProfile.first_name === undefined || userProfile.first_name === '' || !namePattern.test(userProfile.first_name);

      case 'last_name':
        return userProfile.last_name === undefined || userProfile.last_name === '' || !namePattern.test(userProfile.last_name);

      case 'username':
        return userProfile.username === undefined || userProfile.username === '' || !namePattern.test(userProfile.username);

      case 'email':
        return !contactEmailPattern.test(userProfile.email) || userProfile.email === undefined || userProfile.email === '';

      case 'confirm_email':
        return (
          userProfile.confirm_email === undefined || userProfile.confirm_email === '' || userProfile.email !== userProfile.confirm_email
        );

      case 'phone':
        return userProfile.phone !== '' && !contactPhonePattern.test(userProfile.phone);

      default:
        break;
    }
  };

  useEffect(() => {
    setIsNewUser(!formUser);
  }, []);

  const isFormValid = () => {
    return (
      userProfile.first_name?.trim() !== '' &&
      userProfile.last_name?.trim() !== '' &&
      userProfile.username?.trim() !== '' &&
      userProfile.email?.trim() !== '' &&
      userProfile.email === userProfile.confirm_email &&
      contactEmailPattern.test(userProfile.email) &&
      namePattern.test(userProfile.first_name) &&
      namePattern.test(userProfile.last_name) &&
      namePattern.test(userProfile.username)
    );
  };

  const onSaveUser = () => {
    setIsSave(false);
    if (isFormValid()) {
      onSave(userProfile, isNewUser ? FormMode.Add : FormMode.Edit);
    } else {
      setIsSave(true);
    }
  };

  return (
    <Modal size='lg' show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {isNewUser ? t('common.add') : t('common.edit')} {t('user.User')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {onSaveLoading ? (
          <ComponentLoading style={{ margin: '100px' }} />
        ) : (
          <Grid>
            <Grid container spacing={1}>
              <Grid item>
                <Alert className='alert-box' variant='outlined' severity='info'>
                  {t('validate.requiredFields')}
                </Alert>
                <Box className='tab-body' mt={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <label className='input-label'>
                        {t('userManagement.firstName')} {'  *'}
                      </label>
                      <TextField
                        value={userProfile.first_name}
                        onChange={event => {
                          genericHandleChange('first_name', event.target.value);
                        }}
                        variant='outlined'
                        fullWidth
                        helperText={handleHelperTextProp('first_name')}
                        error={isSave && handleErrorProp('first_name')}
                        size='small'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <label className='input-label'>
                        {t('userManagement.lastName')} {'  *'}
                      </label>
                      <TextField
                        value={userProfile.last_name}
                        onChange={event => {
                          genericHandleChange('last_name', event.target.value);
                        }}
                        color={userProfile.last_name ? 'success' : 'error'}
                        variant='outlined'
                        fullWidth
                        helperText={handleHelperTextProp('last_name')}
                        error={isSave && handleErrorProp('last_name')}
                        size='small'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <label className='input-label'>
                        {t('user.userName')}
                        {'  *'}
                      </label>
                      <TextField
                        value={userProfile.username}
                        onChange={event => {
                          genericHandleChange('username', event.target.value);
                        }}
                        variant='outlined'
                        fullWidth
                        disabled={!isNewUser}
                        helperText={handleHelperTextProp('username')}
                        error={isSave && handleErrorProp('username')}
                        size='small'
                      />
                    </Grid>

                    <>
                      <Grid item xs={12}>
                        <label className='input-label'>
                          {t('userManagement.email')} {'  *'}
                        </label>
                        <TextField
                          value={userProfile.email}
                          onChange={event => {
                            genericHandleChange('email', event.target.value);
                          }}
                          variant='outlined'
                          fullWidth
                          helperText={handleHelperTextProp('email')}
                          error={isSave && handleErrorProp('email')}
                          size='small'
                        />
                        {isNewUser && (
                          <label className='input-label' style={{ marginTop: 5 }}>
                            {t('userManagement.emailVerification')}
                          </label>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <label className='input-label'>
                          {t('userManagement.confirmEmail')} {'  *'}
                        </label>
                        <TextField
                          value={userProfile.confirm_email}
                          onChange={event => {
                            genericHandleChange('confirm_email', event.target.value);
                          }}
                          variant='outlined'
                          fullWidth
                          color={userProfile.email !== userProfile.confirm_email ? 'error' : 'success'}
                          helperText={handleHelperTextProp('confirm_email')}
                          error={isSave && handleErrorProp('confirm_email')}
                          size='small'
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <label className='input-label'>{t('userManagement.phone')}</label>
                        <TextField
                          value={userProfile.phone}
                          onChange={event => {
                            genericHandleChange('phone', event.target.value);
                          }}
                          variant='outlined'
                          fullWidth
                          size='small'
                          helperText={handleHelperTextProp('phone')}
                          error={handleErrorProp('phone')}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <label className='input-label'>{t('userManagement.clinicalRole')}</label>
                        <Select
                          size='small'
                          value={userProfile.clinical_role !== undefined ? userProfile.clinical_role : 'Physician'}
                          onChange={event => {
                            genericHandleChange('clinical_role', event.target.value);
                          }}
                          fullWidth
                        >
                          <MenuItem value={'Physician'}>{t('userManagement.physician')}</MenuItem>
                          <MenuItem value={'Nurse'}>{t('userManagement.nurse')}</MenuItem>
                          <MenuItem value={'Resident'}>{t('userManagement.resident')}</MenuItem>
                          <MenuItem value={'Clinical Technician'}>{t('userManagement.clinicalTechnician')}</MenuItem>
                          <MenuItem value={'Administrator'}>{t('userManagement.administrator')}</MenuItem>
                        </Select>
                      </Grid>

                      <Grid item xs={12}>
                        <label className='input-label'>{t('userManagement.systemRole')}</label>
                        <Select
                          value={userProfile.system_role}
                          size='small'
                          onChange={event => {
                            genericHandleChange('system_role', event.target.value);
                          }}
                          fullWidth
                        >
                          {selectedRoles.map(el => {
                            return (
                              <MenuItem key={el.value} value={el.value}>
                                {el.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Grid>
                    </>
                  </Grid>

                  <Grid item xs={12} pt={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size='medium'
                          checked={userProfile.status === '1'}
                          onChange={event => {
                            const newValue = event.target.checked ? '1' : '0';
                            genericHandleChange('status', newValue);
                          }}
                          name={userProfile.status}
                        />
                      }
                      label={t('common.active')}
                    />
                  </Grid>
                </Box>
              </Grid>
              <Divider variant='fullWidth' />
            </Grid>
            <Box className='tab-footer' style={{ marginTop: 20 }}>
              <Box className='action-buttons'>
                <Button variant='contained' color='secondary' disableElevation onClick={onClose}>
                  {t('common.cancel')}
                </Button>
                <Button variant='contained' disableElevation onClick={onSaveUser}>
                  {t('common.save')}
                </Button>
              </Box>
            </Box>
          </Grid>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default GroupUsersForm;
