/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useMemo, useReducer } from 'react';
import { Config, User, Structure, AppMode } from 'src/core/models/session.models';
import { Patient } from 'src/core/models/patient.models';
import { Wound, WoundLocationsListModel, pdfReportSetting } from 'src/core/models/wound.models';
import AppReducer from './AppReducer';
import { AlertDialogProps, SnackBarProps } from 'src/core/models/dialog.models';
import { Abilities } from 'src/core/models/user.models';
import { Measurement, MeasurementClass } from 'src/core/models/measurement.models';
import { PDF_REPORT_SETTING } from 'src/core/data/wounds';

interface AppContext {
  appMode: AppMode;
  config: Config;
  user: User;
  structure: Structure;
  patient: Patient;
  wound: Wound;
  abilities: Abilities;
  alertDialogProps: any;
  snackBarProps: SnackBarProps;
  lastMeasurement: Measurement;
  woundLocations: WoundLocationsListModel;
  selectedSite: Structure;
  selectedAccount: Structure;
  selectedGroup: Structure;
  setAppMode: (mode: AppMode) => void;
  setSelectedSite: (site: Structure) => void;
  setSelectedAccount: (account: Structure) => void;
  setSelectedGroup: (group: Structure) => void;
  setLastMeasurement: (measurement: Measurement | Record<string, never>) => void;
  clearLastMeasurement: () => void;
  setConfig: (config: Config) => void;
  setAbilities: (Abilities: Abilities) => void;
  setUser: (user: User) => void;
  setStructure: (structure: Structure) => void;
  setPatient: (patient: Patient) => void;
  setWound: (wound: Wound) => void;
  clearSession: () => void;
  setAlertDialogProps: (alertDialogProps?: AlertDialogProps) => void;
  setSnackBarProps: (snackBarProps?: SnackBarProps) => void;
  pdfReportSetting: pdfReportSetting;
  setPdfReportSetting: (value: pdfReportSetting) => void;
  setWoundLocations: (locations: WoundLocationsListModel) => void;
}

const initPdfReportSettingFunc = (): pdfReportSetting => {
  let initPdfReportSetting = JSON.parse(
    localStorage.getItem(PDF_REPORT_SETTING) ??
      '{"dateRangeSelection":"all","measurementDetails":true,"signature":false,"appendix":false,"deidentify":false,"isCustomLogo":"0"}',
  );

  if (localStorage.getItem(PDF_REPORT_SETTING)) {
    initPdfReportSetting = {
      ...initPdfReportSetting,
      dateRange:
        initPdfReportSetting?.dateRange?.startDate && initPdfReportSetting?.dateRange?.endDate
          ? { startDate: new Date(initPdfReportSetting.dateRange.startDate), endDate: new Date(initPdfReportSetting.dateRange.endDate) }
          : null,
    };
  }
  return initPdfReportSetting;
};

const initialState: AppContext = {
  appMode: JSON.parse(sessionStorage.getItem('_appMode') ?? '{}'),
  config: new Config({
    ...JSON.parse(sessionStorage.getItem('_config') ?? '{}'),
    ...JSON.parse(localStorage.getItem('ldapConfig') ?? '{}'),
  }),
  user: new User(JSON.parse(sessionStorage.getItem('_user') ?? '{}')),
  structure: new Structure(JSON.parse(sessionStorage.getItem('_structure') ?? '{}')),
  patient: new Patient(JSON.parse(sessionStorage.getItem('_patient') ?? '{}')),
  wound: new Wound(JSON.parse(sessionStorage.getItem('_wound') ?? '{}')),
  abilities: new Abilities(JSON.parse(sessionStorage.getItem('_abilities') ?? '{}')),
  alertDialogProps: new AlertDialogProps(),
  snackBarProps: new SnackBarProps(),
  lastMeasurement: new MeasurementClass(JSON.parse(sessionStorage.getItem('_lastMeasurement') ?? '{}')),
  woundLocations: new WoundLocationsListModel(JSON.parse(sessionStorage.getItem('_woundLocations') ?? '{}')),
  selectedSite: new Structure(JSON.parse(sessionStorage.getItem('_selectedSite') ?? '{}')),
  selectedAccount: new Structure(JSON.parse(sessionStorage.getItem('_selectedAccount') ?? '{}')),
  selectedGroup: new Structure(JSON.parse(sessionStorage.getItem('_selectedGroup') ?? '{}')),
  setAppMode: () => {},
  setSelectedGroup: () => {},
  setSelectedAccount: () => {},
  setSelectedSite: () => {},
  setLastMeasurement: () => {},
  clearLastMeasurement: () => {},
  setConfig: () => {},
  setAbilities: () => {},
  setUser: () => {},
  setStructure: () => {},
  setPatient: () => {},
  setWound: () => {},
  clearSession: () => {},
  setAlertDialogProps: () => {},
  setSnackBarProps: () => {},
  pdfReportSetting: initPdfReportSettingFunc(),
  setPdfReportSetting: () => {},
  setWoundLocations: () => {},
};

const AppContext = createContext(initialState);

export const AppContextProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  const setAppMode = (mode: AppMode) => {
    dispatch({
      type: 'SET_APP_MODE',
      payload: mode,
    });
  };
  const setAbilities = (abilities: Abilities) => {
    dispatch({
      type: 'SET_ABILITIES',
      payload: abilities,
    });
  };

  const setConfig = (config: Config) => {
    dispatch({
      type: 'SET_CONFIG',
      payload: config,
    });
  };

  const setUser = (user: User) => {
    dispatch({
      type: 'SET_USER',
      payload: user,
    });
  };

  const setStructure = (structure: Structure) => {
    dispatch({
      type: 'SET_STRUCTURE',
      payload: structure,
    });
  };

  const setPatient = (patient: any) => {
    dispatch({
      type: 'SET_PATIENT',
      payload: patient,
    });
  };

  const setWound = (wound: Wound) => {
    dispatch({
      type: 'SET_WOUND',
      payload: wound,
    });
  };

  const clearSession = () => {
    dispatch({ type: 'CLEAR_SESSION' });
  };

  const setAlertDialogProps = (dialog?: AlertDialogProps) => {
    dispatch({
      type: 'SET_ALERT_DIALOG_PROPS',
      payload: dialog,
    });
  };
  const setSnackBarProps = (dialog?: SnackBarProps) => {
    dispatch({
      type: 'SET_SNACKBAR_PROPS',
      payload: dialog,
    });
  };
  const setPdfReportSetting = (value: pdfReportSetting) => {
    dispatch({
      type: 'SET_PDF_REPORT_SETTING',
      payload: value,
    });
  };

  const setLastMeasurement = (measurement: Measurement | Record<string, never>) => {
    dispatch({
      type: 'SET_LAST_MEASUREMENT',
      payload: measurement,
    });
  };

  const clearLastMeasurement = () => {
    dispatch({ type: 'CLEAR_LAST_MEASUREMENT' });
  };

  const setWoundLocations = (locations: WoundLocationsListModel) => {
    dispatch({
      type: 'SET_WOUND_LOCATIONS',
      payload: locations,
    });
  };

  const setSelectedSite = (selectedSite: Structure) => {
    dispatch({
      type: 'SET_SELECTED_SITE',
      payload: selectedSite,
    });
  };

  const setSelectedAccount = (selectedAccount: Structure) => {
    dispatch({
      type: 'SET_SELECTED_ACCOUNT',
      payload: selectedAccount,
    });
  };

  const setSelectedGroup = (selectedGroup: Structure) => {
    dispatch({
      type: 'SET_SELECTED_GROUP',
      payload: selectedGroup,
    });
  };

  const contextValue = useMemo(
    () => ({
      appMode: state.appMode,
      config: state.config,
      user: state.user,
      structure: state.structure,
      patient: state.patient,
      wound: state.wound,
      alertDialogProps: state.alertDialogProps,
      snackBarProps: state.snackBarProps,
      abilities: state.abilities,
      lastMeasurement: state.lastMeasurement,
      woundLocations: state.woundLocations,
      selectedSite: state.selectedSite,
      selectedAccount: state.selectedAccount,
      selectedGroup: state.selectedGroup,
      setAppMode,
      setLastMeasurement,
      clearLastMeasurement,
      setAbilities,
      setConfig,
      setUser,
      setStructure,
      setPatient,
      setWound,
      clearSession,
      setAlertDialogProps,
      setSnackBarProps,
      pdfReportSetting: state.pdfReportSetting,
      setPdfReportSetting,
      setWoundLocations,
      setSelectedSite,
      setSelectedAccount,
      setSelectedGroup,
    }),
    [
      state.appMode,
      state.config,
      state.user,
      state.structure,
      state.patient,
      state.wound,
      state.alertDialogProps,
      state.snackBarProps,
      state.abilities,
      state.lastMeasurement,
      state.woundLocations,
      state.selectedSite,
      state.selectedAccount,
      state.selectedGroup,
      setAppMode,
      setLastMeasurement,
      clearLastMeasurement,
      setAbilities,
      setConfig,
      setUser,
      setStructure,
      setPatient,
      setWound,
      clearSession,
      setAlertDialogProps,
      setSnackBarProps,
      state.pdfReportSetting,
      setPdfReportSetting,
      setWoundLocations,
      setSelectedSite,
      setSelectedAccount,
      setSelectedGroup,
    ],
  );

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

export default AppContext;
