import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

interface Props {
  loadingAnimation?: boolean;
  open: boolean;
  title: any;
  content: any;
  closeLabel?: string;
  confirmLabel?: string;
  onClose: () => void;
  onConfirm: () => void;
}

export default function AlertDialog(props: Readonly<Props>) {
  const { loadingAnimation = false, open, title, content, closeLabel, confirmLabel, onConfirm, onClose } = props;
  const { t } = useTranslation();
  const [loadingState, setLoadingState] = useState(false);

  const handleConfirmClick = () => {
    if (loadingAnimation) {
      setLoadingState(true);
    }
    onConfirm();
    setLoadingState(false);
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={onClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='secondary' onClick={onClose} disableElevation>
            {closeLabel ?? t('common.cancel')}
          </Button>
          <LoadingButton type='submit' variant='contained' loadingPosition='center' loading={loadingState} onClick={handleConfirmClick}>
            {confirmLabel ?? t('proceed')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
