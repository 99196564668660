import './sideMenu.style.scss';
import { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router';
import AppContext from 'src/core/context/global/AppContext';
import { SideContainer } from 'src/components/containers/sideContainer/SideContainer.component';
import classNames from 'classnames';
import SiteMenu from './SiteMenu.component';
import AccountMenu from './AccountMenu.component';
import GroupMenu from './GroupMenu.component';
import AdminMenu from './AdminMenu.component';
import { can, getUserRole } from 'src/core/utils/global.utils';
import { colors } from '../../../assets/styles/theme.style';
import AuthService from 'src/core/services/auth.service';
import { Structure, StructureType, StructureTypes } from 'src/core/models/session.models';
import { get } from 'jquery';

const SideMenu = () => {
  const { structure, selectedGroup, selectedAccount, selectedSite, clearSession, user, abilities } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();

  const getStructure = (): Structure => {
    if (selectedSite.id !== 0) {
      return selectedSite;
    }
    if (selectedAccount.id !== 0) {
      return selectedAccount;
    }
    if (selectedGroup.id !== 0) {
      return selectedGroup;
    }
    return structure;
  };

  const renderMenu = (structureType: StructureTypes) => {
    switch (structureType) {
      case StructureType.Site:
        return (
          <SiteMenu
            openPage={openPage}
            signOut={signOut}
            getItemClassName={getItemClassName}
            openTelehealth={openTelehealth}
            openAbout={openAbout}
          />
        );
      case StructureType.Account:
        return (
          <AccountMenu
            openPage={openPage}
            signOut={signOut}
            getItemClassName={getItemClassName}
            openTelehealth={openTelehealth}
            openAbout={openAbout}
          />
        );
      case StructureType.Group:
        return <GroupMenu openPage={openPage} signOut={signOut} getItemClassName={getItemClassName} />;
      case undefined:
        return (
          <>
            {can(getUserRole(user, structure), ['view_ekare_admin_menu'], abilities) && (
              <AdminMenu openPage={openPage} signOut={signOut} getItemClassName={getItemClassName} />
            )}
          </>
        );
    }
  };

  const openPage = (page: string) => {
    navigate(page);
  };

  const openTelehealth = () => {
    // Open Telehealth
  };

  const openAbout = () => {
    navigate('/about');
  };

  const signOut = async () => {
    try {
      await AuthService.signOut();
    } catch (error) {
      // No logs
    } finally {
      clearSession();
      navigate('/login', { replace: true });
    }
  };

  const getItemClassName = (pathname?: string) => {
    return classNames('item', { active: location.pathname === pathname });
  };

  const root = document.documentElement;
  root.style.setProperty('--active-color', colors.activeSideMenuBtn);
  root.style.setProperty('--active-shadow-color', colors.activeShadowColor);
  root.style.setProperty('--item-color', colors.sideMenuText);

  // const userRole: any = user.structures === undefined && { ...structure, role: user.role };

  return <SideContainer>{renderMenu(getStructure().type)}</SideContainer>;
};

export default SideMenu;
